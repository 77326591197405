<template>
    <div>
        <Nav />
        <div class="center land-box">
            <!-- Announcement layout, including title, content table, and nearby tables -->
            <div class="announcement">
                <div class="announcement-title">
                    <h2>土地转让公告发布</h2>
                    <h4>川自然资告字[2023]34433号</h4>
                    <div class="announcement-time">发布时间：2023-01-06 17:09:56</div>
                </div>
                <div class="announcement-content">
                    测试内容测试内容测试内容测试内容测试内容测试内容测试内容
                    测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容
                    测试内容测试内容测试内容测试内容测试内容测试内容测试内容
                    测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容
                    测试内容测试内容测试内容测试内容测试内容测试内容测试内容
                    测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容
                </div>
                <h4 class="group-title">标的信息</h4>
                <table class="matter-table">
                    <tr>
                        <td>标的编号</td>
                        <td>川DZR[2023]37031114</td>
                    </tr>
                    <tr>
                        <td>公告时间</td>
                        <td>2023-01-06 17:06:15</td>
                    </tr>
                    <tr>
                        <td>流转方式</td>
                        <td>转让</td>
                    </tr>
                    <tr>
                        <td>交易方式</td>
                        <td>网上交易（直接交易）</td>
                    </tr>
                    <tr>
                        <td>交易时间</td>
                        <td>2023-01-06 19:08:54</td>
                    </tr>
                    <tr>
                        <td>交易价款</td>
                        <td>100万元</td>
                    </tr>
                    <tr>
                        <td>购买资格条件</td>
                        <td></td>
                    </tr>
                </table>
                <h4 class="group-title">地块信息</h4>
                <el-table border :data="landData" class="land-table">
                    <el-table-column prop="no" label="地块编号"></el-table-column>
                    <el-table-column prop="owner" label="土地权属单位"></el-table-column>
                    <el-table-column prop="location" label="土地位置"></el-table-column>
                    <el-table-column prop="area" label="使用权面积(㎡)"></el-table-column>
                    <el-table-column prop="useto" label="土地用途"></el-table-column>
                </el-table>
                <h4 class="group-title">附件</h4>
                <el-table border :data="files" class="land-table">
                    <el-table-column prop="no" label="序号"></el-table-column>
                    <el-table-column prop="fileName" label="文件名"></el-table-column>
                    <el-table-column prop="file" label="文件"></el-table-column>
                    <el-table-column prop="action" label="操作">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="downloadFile(scope.$index, tableData)" type="text" size="small">
                                下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="support-format">说明：支持.rar .zip .doc .docx .pdf .jpg .png 格式 </div>
            </div>
        </div>
        <footer2 />
    </div>
</template>
<script>
import Nav from "@/components/nav";
import footer2 from "components/footer.vue";

export default {
    name: "AnnouncementDetail",
    components: {
        Nav,
        footer2,
    },
    data() {
        return {
            landData: [
                {
                    no: "川DZR[2023]37031114",
                    owner: "念达陈",
                    location: "成都市武侯区",
                    area: 1000,
                    useto: "商业用地",
                },
            ],
            files: [
                {
                    no: 1,
                    fileName: "测试文件",
                    file: "测试文件.docx",
                },
            ],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() { },
        downloadFile() { },
    },
};
</script>
<style lang="scss" scoped>
.announcement {
    background-color: #fff;
    padding: 50px 24px;
    margin-top: 24px;

    .announcement-title {
        text-align: center;

        h4 {
            font-weight: normal;
            color: #333;
            margin-top: 12px;
        }

        .announcement-time {
            font-size: 14px;
            color: #999;
            margin-top: 24px;
            text-align: right;
        }
    }

    .announcement-content {
        margin: 24px 0;
        font-size: 14px;
        color: #333;
        line-height: 24px;
    }

    .matter-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-top: 24px;

        tr {
            height: 48px;
            border-bottom: 1px solid #eee;
        }

        td {
            font-size: 16px;
            color: #333;
            padding: 0 14px;

            &:first-child {
                width: 120px;
                text-align: right;
            }
        }
    }

    .support-format {
        font-size: 14px;
        color: #999;
        margin-top: 6px;
    }
}
</style>